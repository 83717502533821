import React from "react";
import {
  Box,
  Container,
  Button,
  Heading,
  Text,
  List,
  ListItem,
  Link,
  Icon,
  Divider,
  Input,
} from "$ui";
import Logo from "$components/Logo";
import { EmailIcon, PhoneIcon, InfoIcon } from "@chakra-ui/icons";
import { useI18n } from "$i18n";

export default function Footer() {
  const { t } = useI18n();
  return (
    <Box bgColor="surface" py="3rem">
      <Container maxW="6xl" display="flex" flexDirection="column" gap={6}>
        <Box
          display="flex"
          justifyContent="space-between"
          gap={8}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box display="flex" flexDirection="column" gap={5}>
            <Logo textAlign="left" />
            {/* <Box display="flex" justifyContent="left" gap={3}>
              <InfoIcon />
              <InfoIcon />
              <InfoIcon />
            </Box> */}
          </Box>
          <Box textAlign="left" display="flex" flexDirection="column" gap={5}>
            <Heading as="h6" fontSize="1rem">
              {t("ConnexionEnt.Footer.Questions")}
            </Heading>
            <Box display="flex" flexDirection="column" gap={2}>
              <Text>{t("ConnexionEnt.Footer.SendEmail")}</Text>
              <Link
                variant="primaryLink"
                display="flex"
                alignItems="center"
                gap={3}
              >
                <EmailIcon color="primary" />
                enterpriseconnexion@gmail.com
              </Link>
            </Box>
            <Box display="flex" flexDirection="column" gap={2}>
              <Text>{t("ConnexionEnt.Footer.OrCallAt")}</Text>
              <Link
                variant="primaryLink"
                display="flex"
                alignItems="center"
                gap={3}
              >
                <PhoneIcon color="primary" />
                +1 888 567 9090
              </Link>
            </Box>
          </Box>
          <Box
            textAlign="left"
            display="flex"
            flexDirection="column"
            gap={5}
            maxW={{ lg: "350px" }}
          >
            <Heading as="h6" fontSize="1rem">
            {t("ConnexionEnt.Footer.Newsletter.heading")}
            </Heading>
            <Text>
            {t("ConnexionEnt.Footer.Newsletter.body")}
            </Text>
            <Box display="flex" flexDirection="column" gap={5} maxW="350px">
              <Input bgColor="white" py="1.5rem" type="text" placeholder={t("ConnexionEnt.Global.Name")} />
              <Input bgColor="white" py="1.5rem" type="email" placeholder={t("ConnexionEnt.Global.Email")} />
              <Button variant="primaryButton"> {t("ConnexionEnt.Global.Subscribe")}</Button>
            </Box>
          </Box>
        </Box>
        <Divider orientation="horizontal" />
        <Box fontSize=".8rem" display="flex" justifyContent="space-between ">
          <Box>
            <Text>(c) Wideas Corporation 2023</Text>
          </Box>
          <Box display="flex" gap={3}>
            <Link>{t("ConnexionEnt.Global.ContactUs")}</Link>
            <Link>{t("ConnexionEnt.Footer.PrivacyPolicy")}</Link>
            <Link>{t("ConnexionEnt.Footer.TermsConditions")}</Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
