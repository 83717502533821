import React from "react";
import { Menu, MenuList, MenuButton, MenuItem, Button, useColorModeValue } from "@chakra-ui/react";
import { useI18n } from "$i18n";
import { useNavigate, useLocation } from "react-router-dom";

export default function LanguagePicker() {
    const navigate = useNavigate();
    const location = useLocation();

    const { t, langCode, links, langs, setLang } = useI18n();

  return (
    <Menu>
      <MenuButton as={Button} variant="none!important" m="0" minW="unset" p={0}>
        {langCode.toUpperCase()}
      </MenuButton>
      <MenuList  display="flex" flexDirection="column" gap={2} border="0" p={3} boxShadow="xl" top="-.45rem" position="relative" zIndex="2" color="bodyText">
        {langs.map((lang, idx) => {
          return (
            <MenuItem
              key={idx}
              borderRadius="md"
              fontWeight={500}
              onClick={() => {
                setLang(lang.Code);
                const newLang = lang.Code;
                const splitPathName = location.pathname.split("/");
                console.log(splitPathName)
                //fix later to handle deeper links
                const page = splitPathName.length >= 4 ?  
                `${splitPathName[splitPathName.length - 2]}/${splitPathName[splitPathName.length - 1]}` : 
                splitPathName[splitPathName.length - 1];
                console.log(page)
                if (!page) {
                  navigate(`/${lang.Code}/`);
                  window.location.reload();
                  return;
                }
                const redirectValue = links[newLang].find((link) => {
                  if (
                    link.Key.toLowerCase().contains(page) ||
                    link.Value === page ||
                    t(`AutoTrace.Links.${link.Key}`) === page
                  ) {
                    console.log(link);
                    return link;
                  }
                })?.Value;
                if (redirectValue) {
                  navigate(`/${lang.Code}/${redirectValue}`);
                  window.location.reload();
                }
              }}
              _active={{ color: "primary", bg: useColorModeValue("pink.50", "gray.900") }}
              _hover={{ color: "primary", bg: useColorModeValue("pink.50", "gray.900") }}
              _focus={{ color: "primary", bg: useColorModeValue("pink.50", "gray.900") }}
            >
              {lang.Name}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
