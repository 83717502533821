// Copyright 2022 @fto-consult/Boris Fouomene. All rights reserved.
// Use of this source code is governed by a BSD-style
// license that can be found in the LICENSE file.

import session from "$session";
import {isNonNullString} from "$utils";
import defaultLang,{defaultLangs} from "./defaultLang";
import {isObj} from "$utils";
const langsSessionKeys ="i18n.langs";


const sessionKey = "i18n.lang.session";
const lastVersionKey = "i18n.app_versionLocalResources";
const localLinksResources = "i18n.app_languagesLinksResources";
const localResourcesSessionKey = "i18n.app_languagesLocalResources";

export const getLang = ()=>{
    let l = session.get(sessionKey);
    return isNonNullString(l)? l : defaultLang;
}

export const getLastVersion = ()=>{
    const l = session.get(lastVersionKey);
    return typeof l =='number'? l : undefined;
}
export const setLastVersion = (lastVersion)=>{
    lastVersion = typeof lastVersion =='number'? lastVersion : lastVersion ?  parseInt(lastVersion) : undefined;
    if(typeof lastVersion =='number'){
        session.set(lastVersionKey,lastVersion);
        return true;
    }
    return false;
}

export const setLang = (lang)=>{
    lang = (isNonNullString(lang)? lang : defaultLang).toLowerCase().trim();
    session.set(sessionKey,lang);
    return lang;
}

export const getLangs = ()=>{
    const langs = [];
    const langsSess = session.get(langsSessionKeys);
    if(Array.isArray(langsSess)){
        langsSess.map(lang=>{
            if(lang && lang?.code && lang.name){
                langs.push(lang);
            }
        })
    }
    return (langs.length && langs || Object.keys(defaultLangs).map((l)=>{
        return {code:l,name:defaultLangs[l]};
    }));
}
export const setLangs = (langs)=>{
    if(Array.isArray(langs) && langs.length){
        session.set(langsSessionKeys,langs);
        return true;
    }
    return false;
}

/****
    @param {object} : {
        [langCode] : {....keys}
    }
*/
export const getLocalResources = ()=>{
    const localResources = session.get(localResourcesSessionKey);
    if(!isObj(localResources) || localResources.length < 1){
        return null;
    }
    return getLangs().filter(({code})=>!!(isNonNullString(code) && isObj(localResources[code]) && localResources[code].length > 0)).length ? localResources : null;
}

export const setLocalResources = (localResources)=>{
    if(!isObj(localResources) || localResources.length < 1){
        return null;
    }
    session.set(localResourcesSessionKey,localResources);
    return true;
}

export const getLinksResources = ()=>{
    const localResources = session.get(localLinksResources);
    if(!isObj(localResources) || localResources.length < 1){
        return null;
    }
    return localResources
}

export const setLinksResources = (localResources) =>{
    if(!isObj(localResources) || localResources.length < 1){
        return null;
    }
    session.set(localLinksResources,localResources);
    return true;
}

export default {
    setLang,
    getLang,
    getLangs,
    setLangs,
    getLastVersion,
    setLastVersion,
    getLocalResources,
    setLocalResources,
    getLinksResources,
    setLinksResources
}