import React, {forwardRef} from 'react'
import { Button } from "$ui";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export const PrimaryButton = forwardRef(({ iconPosition , ...props}, ref) => {
  return (
    <Button display="flex" gap={2} py="1.5rem" alignItems="center" variant="primaryButton" {...props}>
        {/* { iconPosition && iconPosition === "left" ? <ArrowForwardIcon position="relative" bottom="-.05rem" /> : ''} */}
        {props.children}
        { iconPosition && iconPosition === "right" ? <ArrowForwardIcon position="relative" bottom="-.05rem" /> : ''}
    </Button>
  )
})
