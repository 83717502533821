import { Box, Container, Heading, Image, Text } from "@chakra-ui/react";
import React, { forwardRef } from "react";
import { PrimaryButton } from "../Buttons";
import snippetImg1 from "$images/commitment-snippet-1.jpg";
import { useI18n } from "$i18n";

export const CommitmentSnippet = forwardRef(
  ({ headingText, bodyText, reverse, ...props }, ref) => {
    const { t } = useI18n();
    return (
      <Box bgColor={reverse ? "surface" : "initial"}>
        <Container maxW="6xl">
          <Box
            as="section"
            {...props}
            display="flex"
            flexDirection={{
              base: "column",
              lg: reverse ? "row-reverse" : "row",
            }}
            py={"4rem"}
            gap={{ base: 5, xl: 90 }}
            alignItems="center"
            maxW={{ base: "750px", lg: "none" }}
            mx="auto"
          >
            <Box
              textAlign="left"
              display="flex"
              flexDirection="column"
              gap={{ base: 3, md: 5, lg: 7 }}
              maxW="750px"
              w="fill-available"
            >
              <Heading as="h1" fontSize="xl">
                {headingText}
              </Heading>
              <Text>{bodyText}</Text>
              <PrimaryButton
                display={{ base: "none", lg: "flex" }}
                iconPosition="right"
              >
                {t("ConnexionEnt.Global.LearnMore")}
              </PrimaryButton>
            </Box>
            <Box display="flex" flexDirection="column" gap={3} w="100%">
              <Image w="100%" src={snippetImg1} maxW="750px" />
              <PrimaryButton
                display={{ base: "flex", lg: "none" }}
                iconPosition="right"
                >
                {t("ConnexionEnt.Global.LearnMore")}
              </PrimaryButton>
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }
);
