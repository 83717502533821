import axios from "axios";
// import * as http from "http";

// Un-comment the environment you are using.

// DEV
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.maxRedirects = 0

axios.defaults.headers.common["languageCode"] = localStorage.getItem("locale") || (window.navigator.language.substr(0,2) || process.env.REACT_APP_I18N_FALLBACK_LOCALE);

axios.defaults.headers.common["timeZone"] = new Date().getTimezoneOffset();

