import React from "react";
import { Box, Heading, Image, Text, Radio, Divider } from "$ui";
import { PrimaryButton } from "$components/Buttons";
import greenSquares from "$images/GreenSquares.svg";
import { Container, RadioGroup } from "@chakra-ui/react";
import { useI18n } from "$i18n";
import {CustomRadio} from "$components/Radio";

export default function Survey() {
  const { t } = useI18n();
  return (
    <Box
      bgColor="surface"
      py={{ base: "3rem", md: "4.5rem", lg: "6rem" }}
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Container maxW="6xl" display="flex" flexDirection="column" gap={{base: 4, lg: 6}}>
        <Heading as="h3" fontSize="3xl" fontWeight="semibold">{t("ConnexionEnt.Projects.Survey.heading")}</Heading>
        <Text>{t("ConnexionEnt.Projects.Survey.body")}</Text>
        <Box
          p="3rem"
          overflow="clip"
          mx="auto"
          bgColor="white"
          borderRadius="md"
          boxShadow="lg"
          maxW="1400px"
          w="100%"
        >
          <Box position="relative">
            <Box
              as="form"
              position="relative"
              ml="6rem"
              zIndex="1"
              display="flex"
              flexDirection="column"
              gap={5}
              textAlign="left"
            >
              <Heading as="h6" fontSize="lg!important">{t("ConnexionEnt.Projects.Survey.Q1")}</Heading>
              <RadioGroup display="flex" flexDirection="column" gap={3}>
                <CustomRadio options={
                  [
                    t("ConnexionEnt.Projects.Survey.Q1A1"),
                    t("ConnexionEnt.Projects.Survey.Q1A2"),
                    t("ConnexionEnt.Projects.Survey.Q1A3"),
                    t("ConnexionEnt.Projects.Survey.Q1A4"),
                    t("ConnexionEnt.Projects.Survey.Q1A5"),
                    t("ConnexionEnt.Projects.Survey.Q1A6"),
                    t("ConnexionEnt.Projects.Survey.Q1A7")
                ]
                }>
                </CustomRadio>
                {/* <Radio colorScheme="green" spacing="1rem" value="2">
                  {t("ConnexionEnt.Projects.Survey.Q1A2")}
                </Radio>
                <Radio colorScheme="green" spacing="1rem" value="3">
                  {t("ConnexionEnt.Projects.Survey.Q1A3")}
                </Radio>
                <Radio colorScheme="green" spacing="1rem" value="4">
                  {t("ConnexionEnt.Projects.Survey.Q1A4")}
                </Radio>
                <Radio colorScheme="green" spacing="1rem" value="5">
                  {t("ConnexionEnt.Projects.Survey.Q1A5")}
                </Radio>
                <Radio colorScheme="green" spacing="1rem" value="6">
                  {t("ConnexionEnt.Projects.Survey.Q1A6")}
                </Radio>
                <Radio colorScheme="green" spacing="1rem" value="7">
                  {t("ConnexionEnt.Projects.Survey.Q1A7")}
                </Radio> */}
              </RadioGroup>
              <Divider orientation="horizontal" />
              <PrimaryButton mx="unset" ml="auto" iconPosition="right">
                {t("ConnexionEnt.Global.Continue")}
              </PrimaryButton>
            </Box>
            <Image
              position="absolute"
              top="-5rem"
              left="-7rem"
              src={greenSquares}
              transform="rotate(-200deg)"
              style={{ "-webkit-backface-visibility": "hidden" }}
            ></Image>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
