import React from "react";
import {Box} from "$ui";
import Hero from "$components/Hero";
import {SectionOne, SectionTwo, SectionThree} from '$components/Home';

export default function Home() {
  return (
    <Box>
      <Hero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
    </Box>
  );
}
