import React from "react";
import { Box, Heading, Text } from "$ui";
import BannerWhite from "$components/Banners/BannerWhite";
import { Survey } from "$components/Sections";
import { TabsInfo } from "$components/Tabs";
import { Container } from "@chakra-ui/react";
import { useI18n } from "$i18n";

export default function Projects() {
  const { t } = useI18n();

  const tabData = [
    {
      tabName: t("ConnexionEnt.Projects.Benefits.Tab1.label"),
      desc: t("ConnexionEnt.Projects.Benefits.Tab1.desc"),
      list: [
        t("ConnexionEnt.Projects.Benefits.Tab1.List1"),
        t("ConnexionEnt.Projects.Benefits.Tab1.List2"),
        t("ConnexionEnt.Projects.Benefits.Tab1.List3"),
      ],
      buttonText: t("ConnexionEnt.Global.StartExploring"),
    },
    {
      tabName: t("ConnexionEnt.Projects.Benefits.Tab2.label"),
      heading: t("ConnexionEnt.Projects.Benefits.Tab2.heading"),
      desc: t("ConnexionEnt.Projects.Benefits.Tab2.desc"),
      list: [
        t("ConnexionEnt.Projects.Benefits.Tab2.List1"),
        t("ConnexionEnt.Projects.Benefits.Tab2.List2"),
        t("ConnexionEnt.Projects.Benefits.Tab2.List3")
      ],
      buttonText: t("ConnexionEnt.Global.JoinUsNow"),
    },
  ];
  

  return (
    <Box>
      <BannerWhite
        headingText={t("ConnexionEnt.Projects.Banner.heading")}
        bodyText={t("ConnexionEnt.Projects.Banner.body")}
        buttonText={t("ConnexionEnt.Global.StartExploring")}
      />
      <Survey />
      <Box>
        <Container maxW="6xl" display="flex" flexDirection="column" gap={{base: 4, lg: 6}} py={{ base: "3rem", md: "4.5rem", lg: "6rem" }}>
          <Heading>{t("ConnexionEnt.Projects.Benefits.heading")}</Heading>
          <Text>{t("ConnexionEnt.Projects.Benefits.desc")}</Text>
          <TabsInfo tabData={tabData} />
        </Container>
      </Box>
    </Box>
  );
}