import $session from "$session";
import {isObj,extendObj,isNonNullString,defaultStr,defaultObj} from "$utils";

const accessTokenKey = "access-token-skey";
export const USER_SESSION_KEY = "user-session";

export const setToken = (token)=>{
    return $session.set(accessTokenKey,isNonNullString(token) && token||null);
}
export const getToken = ()=>{
    const accessToken = $session.get(accessTokenKey);
    return isNonNullString(accessToken) && accessToken || "";
}

export const getLoggedUserCode = ()=>{
    const u = getLocalUser();
    if(!isObj(u)) return null;
    return defaultStr(u.Ref,u.Contact?.Email,u.Contact?.Mobile);
}

export const removeToken = ()=>{
  return setToken(null);
}
export const isValidToken = (token)=>{
  return isNonNullString(token) || false;
}

export const isSignedIn = x => {
    const u = getLocalUser();
    return isObj(u) && isNonNullString(u.Ref) && isValidToken(getToken())? true : false;
}

export const getLocalUser = x=> {
    const u = $session.get(USER_SESSION_KEY);
    return isObj(u) && u.Ref ? u : null;
};

export const getLoggedUser = getLocalUser;


export const setLocalUser = u => $session.set(USER_SESSION_KEY,u || null);

export const DEFAULT_SESSION_NAME = "USER-DEFAULT-SESSION";

export const getSessionKey = (sessionName)=>{
  sessionName = defaultStr(sessionName,DEFAULT_SESSION_NAME);
  const userCode = getLoggedUserCode();
  if(!isNonNullString(userCode)) return false;
  return sessionName+"-"+userCode;
}

export const getSessionData = (sessionKey,sessionName)=>{
  const key = getSessionKey(sessionName);
  const dat = isNonNullString(key)? defaultObj($session.get(key)) : {};
  if(isNonNullString(sessionKey)){
      return dat[sessionKey]
  }
  return dat;
}

export const setSessionData = (sessionKey,sessionValue,sessionName)=>{
  if(isObj(sessionKey)){
    sessionName = defaultStr(sessionName,sessionValue);
  }
  const key = getSessionKey(sessionName);
  if(!isNonNullString(key)) return false;
  let dat = defaultObj(getSessionData());
  if(isNonNullString(sessionKey)){
      dat[sessionKey] = sessionValue;
  } else if(isObj(sessionKey)){
      extendObj(dat,sessionKey);
  } else {
      return dat;
  }
  $session.set(key,dat);
  return dat;
}