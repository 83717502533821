import {isNonNullString,isObj,defaultStr} from "$utils";
import { fetchResources, fetchLinksResources,fetchLastVersion } from "$api/resources";
import i18n from "./i18n";
import APP from "$app";
import session from "./session";
import {fetch as fetchCountries} from "$api/countries";

export const prepareResources = (resources,filter)=>{
    filter = typeof filter =="function"? filter : x=>true;
    if(!Array.isArray(resources)){
        console.error("Invalid langage resources",resources);
        return null;
    }
    const dictionary = {};
    resources.map((resource,index)=>{
        if(!isObj(resource) || !isNonNullString(resource.Name) || !Array.isArray(resource.Resources) || !filter(resource,index)){
            return;
        }
        const name = resource.Name.trim();
        resource.Resources.map((res)=>{
            if(!isObj(res)|| !isNonNullString(res.Key) || !isNonNullString(res.Value)){
                return;
            }
            dictionary[`${name}.${res.Key}`] = res.Value;
        });
    });
    return dictionary;
}

export const loadResources = (langCode,languages)=>{
    try {
    if(!isNonNullString(langCode)){
        return Promise.reject({message:"The langage code is not supported by the application"});
    }
    return new Promise((resolve,reject)=>{
        langCode = langCode.trim();
        const fetchOpts = {headers:{languageCode:langCode.toLowerCase()}};
        return Promise.all([
            //we load all countries related to the given language, event if lancode is persited
            fetchCountries(fetchOpts,true),
            fetchLastVersion().then(({data:lastVersion})=>{
                if(session.getLastVersion() === lastVersion){
                    const localResources = session.getLocalResources();
                    if(localResources && localResources[langCode]){
                        console.log(localResources)
                        return localResources;
                    }
                }
                    return fetchResources(fetchOpts).then(({data})=>{
                        session.setLastVersion(lastVersion);
                        return {
                            [langCode] :  prepareResources(data)
                        };
                    })
            })
        ]).then(([,resources])=>{
            i18n.reset(langCode,resources);
            APP.trigger(APP.EVENTS.LOAD_I18N_RESOURCES,resources);
            session.setLocalResources(resources);
            resolve(resources)
        }).catch(reject);
    });
} catch (err) {
    console.log(err)
}
}


/***** contains all resources fetched from the api
    @return {
        [resourceK] : {
            [langCode1] : value1,
            [langCode2] : value2,
        }
    }
*/
export const linksResources = {};

export const loadLinksResources = ()=>{
    return Promise.all(session.getLangs().map(({code:languageCode})=>{
        return fetchLinksResources({headers:{
            languageCode,
        }}).then(({data:resources})=>{
            return {languageCode,resources};
        });
    })).then((lResources)=>{
        lResources.map(({languageCode,resources})=>{
            languageCode = languageCode.toLowerCase().trim();
            resources.map((resource,index)=>{
                const name = resource.Name.trim();
                resource.Resources.map((res)=>{
                    if(!isObj(res)|| !isNonNullString(res.Key) || !isNonNullString(res.Value)){
                        return;
                    }
                    const key = `${name}.${res.Key}`;
                    linksResources[key] = isObj(linksResources[key]) ? linksResources[key] : {};
                    linksResources[key][languageCode] = res.Value;
                });
            });
        });
        return linksResources;
    });
}
