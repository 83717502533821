"use client";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Container,
  Link as ChakraLink,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Logo from "$components/Logo";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useI18n } from "$i18n";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import LanguagePicker from "./LanguagePicker";
import { CountrySelect } from "../Buttons";

const isRouteActive = (navItem, currentLink, location) => location.pathname === currentLink || (navItem.children && location.pathname.contains(navItem.children[0].href.split("/")[2]))

export default function NavBar() {
  const { isOpen, onToggle } = useDisclosure();
  const { t, langCode, links } = useI18n();
  const [navItems, setNavItems] = useState([]);
  const hamburgerRef = useRef();
  const navBarRef = useRef()

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  useEffect(() => {
    const NAV_ITEMS = [
      {
        label: t("ConnexionEnt.Nav.AboutUs.label"),
        href: "/",
      },
      {
        label: t("ConnexionEnt.Nav.Services.label"),
        href: `/${langCode}/${links?.[langCode][0].Value}`,
      },
      {
        label: t("ConnexionEnt.Nav.Commitments.label"),
        href: `/${langCode}/${links?.[langCode][1].Value}`,
      },
      {
        label: t("ConnexionEnt.Nav.Projects.label"),
        href: `/${langCode}/${links?.[langCode][2].Value}`,
      },
    ];
    setNavItems(NAV_ITEMS);
  }, [links]);

  const handleScroll = event => {
    if (
      !navBarRef.current.classList.contains("fixed-nav") &&
      (window.scrollY > 105)
      ) {
      navBarRef.current.classList.add("fixed-nav")
    } else if (
      navBarRef.current.classList.contains("fixed-nav") &&
      window.scrollY <= 105
      ) {
        navBarRef.current.classList.remove("fixed-nav")
    }
  }

  return (
    <Box ref={navBarRef} boxShadow="sm" position="relative" zIndex={1} bg={useColorModeValue("white", "gray.800")}>
      <Container maxW="6xl">
      <Flex
          color={useColorModeValue("gray.600", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          flexDirection={{ base: "row-reverse", md: "row" }}
          align={"center"}
        >
          <Flex
            flex={{ base: 1, md: "auto" }}
            justifyContent="end"
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
            gap={5}
          >
            <Button
              display={{ base: "none", md: "block" }}
              as={Link}
              // to={`/${langCode}/${links?.[langCode][2].Value}`}
              variant="darkGreyOutlineButton"
              px="1rem"
            >
              {t("AutoTrace.Global.Login")}
            </Button>
            <Box
              display={{ base: "flex", md: "none" }}
              alignItems="center"
              gap={4}
            >
              <CountrySelect />
            </Box>
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              ref={hamburgerRef}
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flex={{ base: 1 }}
            justify={{ base: "center", md: "start" }}
          >
            <Logo fontSize="lg" />

            <Flex display={{ base: "none", md: "flex" }}>
              <DesktopNav navItems={navItems} />
            </Flex>
            <Button
              as={Link}
              to={`/${langCode}/${links?.[langCode][2].Value}`}
              display={{ base: "none", md: "inline-flex" }}
              fontSize={{ base: "xs", lg: "sm" }}
              fontWeight={600}
              color={"white"}
              bg={"secondary"}
              _hover={{
                bg: "secondaryDark",
              }}
            >
              Connexion Finder
              {/* {t("ConnexionEnt.Nav.ConnexionFinder.label")} */}
            </Button>
          </Flex>
        </Flex>
          <MobileNav 
            ref={hamburgerRef}
            isOpen={isOpen}
            onToggle={onToggle}
            navItems={navItems}
           />
      </Container>
    </Box>
  );
}

const DesktopNav = ({ navItems }) => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");
  const { langCode, links } = useI18n();
  const location = useLocation();

  return (
    <Stack direction={"row"} spacing={4}>
      {navItems.map((navItem) => {
        let currentLink =
        navItem.children && typeof navItem.children === Array
          ? navItem.children.find(
              (childItem) => childItem.href === location.pathname
            ).href
          : navItem.href;
        return <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <ChakraLink
                as={Link}
                p={2}
                to={navItem.href ?? "#"}
                fontSize={{ base: "xs", lg: "sm" }}
                fontWeight={600}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
                py={isRouteActive(navItem, currentLink, location) ? "1.1rem" : ""}
                  color={
                    isRouteActive(navItem, currentLink, location) ? "primary" : linkColor
                  }
                  borderBottom={
                    isRouteActive(navItem, currentLink, location)
                      ? "2px solid var(--chakra-colors-primary)"
                      : ""
                  }
              >
                {navItem.label}
              </ChakraLink>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav
                      navItems={navItems}
                      key={child.label}
                      {...child}
                    />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      })}
    </Stack>
  );
};

const DesktopSubNav = ({ navItems, label, href, subLabel }) => {
  const { langCode, links } = useI18n();

  return (
    <ChakraLink
      as={Link}
      to={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      bgColor={isRouteActive({}, href, location) ? useColorModeValue("pink.50", "gray.900"): "none"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            fontSize={{ base: "xs", lg: "sm" }}
            color={ isRouteActive({}, href, location) ? "primary" : "grey"}
            fontWeight={500}
            transition={"all .3s ease"}
            _groupHover={{ color: "primary" }}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </ChakraLink>
  );
};

const MobileNav = ({ ref, isOpen, onToggle, navItems, ...props }) => {
  const { t, langCode, links } = useI18n();

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onToggle}
        finalFocusRef={ref}
      >
        <DrawerOverlay top="60px" bottom="0" />
        <DrawerContent
          top="60px!important"
          bottom="0"
          transition="none"
          transform="none"
          bgColor="surface"
          color="darkGrey"
        >
          {/* <DrawerHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box/>
            <DrawerCloseButton position="relative" top="0" right="0" />
          </DrawerHeader> */}

          <DrawerBody>
            <Stack p={4}>
              {navItems.map((navItem) => (
                <MobileNavItem
                  onToggleDrawer={onToggle}
                  key={navItem.label}
                  {...navItem}
                />
              ))}
            </Stack>
          </DrawerBody>

          <DrawerFooter color="white" bgColor="gray.900" justifyContent="space-between">
          <LanguagePicker />
          <Button
              as={Link}
              to={`/${langCode}/${links?.[langCode][2].Value}`}
              fontSize={{ base: "xs", lg: "sm" }}
              fontWeight={600}
              color={"white"}
              bg={"secondary"}
              _hover={{
                bg: "secondaryDark",
              }}
            >
              Connexion Finder
              {/* {t("ConnexionEnt.Nav.ConnexionFinder.label")} */}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const MobileNavItem = ({ label, children, onToggleDrawer, href }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const { langCode, links } = useI18n();

  return (
    <Stack spacing={2} onClick={children && onToggle}>
      <ChakraLink
        as={Link}
        display="flex"
        justifyContent="space-between"
        py={2}
        to={href ?? "#"}
        alignItems="center"
        color={
          location.pathname === href ?
          "primary" :
          useColorModeValue("gray.600", "gray.200")
      }
        onClick={() => !children && onToggleDrawer()}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            color={"whiteAlpha.700"}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </ChakraLink>

      <Collapse
        in={isOpen}
        startingHeight={2}
        animateOpacity
        style={{ marginTop: "0!important" }}
      >
        <Stack pl={4} mb={isOpen ? 2 : 0} align={"start"}>
          {children &&
            children.map((child) => {
              return <ChakraLink
                onClick={() => onToggleDrawer()}
                color={
                  location.pathname === child.href ?
                  "primary" :
                  useColorModeValue("gray.600", "gray.200")
                }
                as={Link}
                key={child.label}
                py={2}
                to={child.href}
              >
                {child.label}
              </ChakraLink>
            })}
        </Stack>
      </Collapse>
    </Stack>
  );
};
