import React from "react";
import { Text, Box, useColorModeValue, useBreakpointValue } from "$ui";
import { Link } from "react-router-dom";

export default function Logo({ ...props }) {
  return (
    <Link to="/">
      <Text
        textAlign={useBreakpointValue({ base: "center", md: "left" })}
        fontFamily={"heading"}
        fontSize={{base: "initial", md: "xs", lg: "sm"}}
        color={useColorModeValue("gray.800", "white")}
        fontWeight={800}
        {...props}
      >
        CONNE
        <Box as="span" color="secondary">
          X
        </Box>
        ION&nbsp;
        <Box as="span" color="primary">
          ENTERPRISE
        </Box>
      </Text>
    </Link>
  );
}
