export {default as isDateObj} from "./isDateObj";

export {default as isNonNullString} from "./isNonNullString";


export function isObj (obj){
    if(!obj) return false;
    return obj && Object.prototype.toString.call(obj) === '[object Object]' && !(obj instanceof RegExp)? true : false;
}

export const isNumber = function(n) {
    if(typeof n === 'number' && !isNaN(n)) return true;
    return n === +n && n === (n|0);
}
