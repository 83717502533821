import React from "react";
import { Box, Button, Heading, Image, Text, Container } from "$ui";
import { PrimaryButton } from "../Buttons";
import greenSquares from "$images/GreenSquares.svg"

export default function BannerWhite({headingText, bodyText, buttonText, ...props}) {
  return (
    <Box position="relative" overflow="hidden" bg="primaryLight">
      <Container display="flex" flexDirection="column" justifyContent="center" maxW="6xl" py={{base:"3rem", md: "4.5rem", lg: "6rem"}} {...props}>
        <Box textAlign="left" display="flex" flexDirection="column" gap={{base: 4, lg: 6}} maxW="680px">
          <Heading color="primaryDarker" as="h1">{headingText}</Heading>
          <Text>
            {bodyText}
          </Text>
          <PrimaryButton iconPosition="right">
            {buttonText}
          </PrimaryButton>
        </Box>
      </Container>
      <Image position="absolute" top="-3rem" right="-3.25rem" src={greenSquares} transform="rotate(-75deg)" style={{"-webkit-backface-visibility": "hidden"}}></Image>
    </Box>
  );
}
