import NavBar from "./NavBar";
import TopBar from "./TopBar";

export default function Nav() {
  return (
    <>
        <TopBar />
        <NavBar />
    </>
  );
}
