import { createSlice } from '@reduxjs/toolkit';
import session from './session';
import {isNonNullString,defaultStr,isObj} from "$utils";
import i18n from './i18n';
import { getLang} from './session';
export const i18nSlice = createSlice({
    name: 'i18n',
    initialState: {
      langCode : getLang(),
      langs : session.getLangs(),
      links : session.getLinksResources(),
    },
    reducers: {
      setLangCode : (state, action) => {
        if(isNonNullString(action.payload) && Array.isArray(state.langs)){
          const lang = action.payload.trim();
          if(!Array.isArray(state.langs)) return;
          const langs = state.langs.map(l=>l.code);
          if(langs.includes(lang) || langs.includes(lang.toLowerCase())){
            const l = lang.toLowerCase();
            i18n.setLang(l);
            state.langCode = l;
          }
        }
      },
      setLangs : (state,action)=>{
        if(Array.isArray(action.payload)){
            const langs = [];
            action.payload.map((l)=>{
              if(!isObj(l)) return;
              const code = defaultStr(l.code,l.Code), name = defaultStr(l.name,l.Name);
              if(!code || !name) return;
              langs.push({...l,code,name});
            });
            if(langs.length){
              session.setLangs(langs);
              state.langs = langs;
            }
        }
      },
      setLinks : (state, action) => {
        if (isObj(action.payload)) {
          state.links = action.payload
        }
      }
    },
});

export const {setLangCode,setLangs, setLinks} = i18nSlice.actions;

export default i18nSlice.reducer;