import React from "react";
import {
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Box,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  TabIndicator,
} from "$ui";
import { CheckIcon } from "@chakra-ui/icons";
import { PrimaryButton } from "$components/Buttons";
import {useEffect} from "react"
export default function TabsInfo({ tabData, ...props }) {
  useEffect(() => {
    console.log(tabData)
  }, [])
  
  return (
    <Tabs position="relative!important" variant="unstyled" align="center"> 
      <TabList>
        {tabData.map((tab) => {
          return (
            <Tab
              fontWeight={600}
              color="surfaceText"
              _selected={{ bg: "primaryLight", color: "secondary", borderTop: "2px solid var(--chakra-colors-secondary)" }}
            >
              {tab.tabName}
            </Tab>
          );
        })}
      </TabList>
      {/* <TabIndicator
        mt="-2.5rem"
        height="2px"
        bgColor="secondary"
      /> */}
      <TabPanels>
        {tabData.map((tab) => {
          return (
            <TabPanel bgColor="primaryLight" p="3rem" display="flex" flexDir="column" gap={5}>
              {tab.heading ? (
                <Heading as="h6" mx="auto" fontWeight={600}>
                  {tab.heading}
                </Heading>
              ) : (
                ""
              )}
              <Box textAlign="left" display="flex" flexDir={{base: "column", lg: "row"}} gap={{base: 5, lg: 20}}>
                <Box fontWeight={500} maxW={{base: "none", lg:"45%"}}>{tab.desc}</Box>
                <Box maxW={{base: "none", lg:"55%"}}>
                  <List  display="flex" flexDir="column" gap={3}>
                    {tab.list.map((listDesc) => {
                      return (
                        <>
                          <ListItem
                            display="flex"
                            alignItems="baseline"
                            gap={3}
                            textAlign="left"
                          >
                            <ListIcon position="relative" bottom="-.2rem">
                              <CheckIcon color="primary" />
                            </ListIcon>
                            <Box
                              dangerouslySetInnerHTML={{ __html: typeof listDesc === "string" ? listDesc : listDesc.props.dangerouslySetInnerHTML.__html}}
                            />
                          </ListItem>
                          <Divider orientation="horizontal" />
                        </>
                      );
                    })}
                    <PrimaryButton mx={{base: "auto", lg: "0"}}>{tab.buttonText}</PrimaryButton>
                  </List>
                </Box>
              </Box>
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
}
