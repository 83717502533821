import "./App.css";
import Nav from "$components/Nav";
import Footer from "$components/Footer";
import { Home, Services, Commitments, Projects, Contact } from "$pages";
import { Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { fetchLocationByIP } from "$api/location";
import { fetchLanguages, fetchLinksResources } from "$api/resources";
import { useI18n, session as i18nSession } from "$i18n";
import { useParams } from "react-router-dom";
import session from "$session";
import { useAuth } from "$auth";
import { Box, Spinner } from "$ui";

export default function App() {
  const { setCurrentCountry } = useAuth();

  const [loading, setLoading] = useState(true);
  const { lang, langs, links, setLinksResources, setLangs, setLang, loadResources, langCode } =
    useI18n();
  const { routeLang } = useParams()

  useEffect(() => {
    async function initData() {
      setLoading(true);
      await setLanguages();
      const changedLang = await setLanguage();
      await setLinks();
      await loadResources(changedLang ? changedLang : langCode);
      setLoading(false);
      let { country } = await fetchLocationByIP();
      session.set("countryCode", country.code);
      setCurrentCountry(country.code)
    }
    initData();
  }, []);

  const setLanguages = async () => {
    try {
      let { data } = await fetchLanguages();
      i18nSession.setLangs(data);
      setLangs(data);
    } catch (err) {
      console.log(err);
    }
  };

  const setLanguage = () => {
    let langToSet = langs.find((lang) =>
    // routeLang === lang
    // may need to fix below as something such as http://.../enterprise would change to eng.
    {
      let splitPath = location.pathname.split("/")
      if (splitPath.find((param) => {
        lang.Code
      })) return lang
    }
    );
    if (langToSet) setLang(langToSet.Code);
    return langToSet?.Code
  };

  const setLinks = async () => {
    try {
      let linksToSet = {};
      for (const lang of langs) {
        let code = lang.code;
        let response = await fetchLinksResources(code);
        linksToSet[code] = response.data[0].Resources;
      }
      i18nSession.setLinksResources(linksToSet);
      setLinksResources(linksToSet)
    } catch (err) {
      console.log(err);
    }
  };

  const renderRoutes = () => {
    try {
      let getElement = (key) => {
        if (key === "Services.link") return <Services />;
        if (key === "Commitments.link") return <Commitments />;
        if (key === "Projects.link") return <Projects />;
        if (key === "Contact.link") return <Contact/>
      };
      return langs.map((lang) => {
        if (links && links[lang.Code]) {
          return links[lang.Code].map((resource) => {
            if (resource.Key.contains("link")) {
              return (
                <Route
                  path={`/${lang.Code}/${resource.Value}`}
                  element={getElement(resource.Key)}
                />
              );
            }
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="App">
        {!loading ?
        <>
          <Nav />
          <Routes>
            {renderRoutes()}
            <Route path="*" element={<Home/>} />
            <Route path="/" element={<Home/>} />
            <Route path="/en" element={<Home/>} />
            <Route path="/fr" element={<Home/>} />
          </Routes>
          <Footer/>
        </>
          :
          <Box w="100vw" h="100vh" display="flex" justifyContent="center" alignItems="center">    
            <Spinner
            thickness='4px'
            speed='0.65s'
            color='primary'
            size='xl'
            />
          </Box>
        }
      </div>
    );
}