import { observable,addObserver,isObservable} from "$lib/observable";
import * as events from "./events";
import config  from "./config";

import {isObj} from "$utils";

const APP_INSTANCE = {};

if(!isObservable(APP_INSTANCE)){
    observable(APP_INSTANCE);
    addObserver(APP_INSTANCE);
}

if(!isObj(APP_INSTANCE.EVENTS)){
    Object.defineProperties(APP_INSTANCE,{
        EVENTS : {value:events,override:false,writable:false},
        CONFIG : {value : config,}
    });
}

export const EVENTS = events;

export default APP_INSTANCE;