import React from "react";
import { Box, Button, Heading, Image, Text, Container } from "$ui";
import BannerSm from "$components/Banners/BannerSm";
import connectServices from "$images/connect-services.jpg"
import projectInitiatives from "$images/projects-initiatives.jpg"
import { ServiceSnippet } from "../Components/Sections";
import { useI18n } from "$i18n";
export default function Services() {
  const { t } = useI18n();
  return (
    <Box mb={{ base: "3rem", md: "4rem", lg: "5rem" }}>
      <BannerSm headingText={t("ConnexionEnt.Services.Banner.title")} />
      <Container maxW="6xl">

      <Box my={{ base: "3rem", md: "4rem", lg: "5rem" }} mx="auto" maxW="3xl">
        <Text>
          {t("ConnexionEnt.Services.desc")}
        </Text>
      </Box>
      <Box display="flex" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between" gap={8}>
        <ServiceSnippet 
            src={connectServices}
            headingText={t("ConnexionEnt.Services.section1.heading")}
            bodyText={t("ConnexionEnt.Services.section1.body")}
        />
        <ServiceSnippet 
            src={projectInitiatives}
            headingText={t("ConnexionEnt.Services.section2.heading")}
            bodyText={t("ConnexionEnt.Services.section2.body")}
        />
      </Box>
      </Container>
    </Box>
  );
}
