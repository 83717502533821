import React from "react";
import { Box, Container, Button, Heading, Text, List, ListItem } from "$ui";
import { useI18n } from "$i18n";

export default function SectionThree() {
  const { t } = useI18n();

  return (
    <Container maxW="6xl">
      <Box
        position="relative"
        my={{ base: "3rem", md: "4.5rem" }}
        py={{ base: "3rem", md: "4.5rem" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="4"
        bgColor="primary"
        borderRadius="lg"
        w="100%"
        color="white"
      >
        <Heading
          as="h2"
          borderBottom="2px solid white"
          pb=".5rem"
          w="fit-content"
        >
          {t("ConnexionEnt.Home.SectionThree.heading")}
        </Heading>
        <Text maxW={{ base: "400px", md: "750px" }}>
          {t("ConnexionEnt.Home.SectionThree.body")}
        </Text>
        <Box position="absolute" top="-.25rem" left="-.5rem">
          <Box
            position="relative"
            bgColor="white"
            opacity="15%"
            borderRadius="xl"
            w="100px"
            h="100px"
          />
          <Box
            position="absolute"
            right="-25%"
            bottom="-25%"
            bgColor="white"
            opacity="15%"
            borderRadius="xl"
            w="90px"
            h="90px"
          />
        </Box>
        <Box position="absolute" bottom="-1rem" right="3rem">
          <Box
            position="relative"
            bgColor="white"
            opacity="15%"
            borderRadius="xl"
            w="100px"
            h="100px"
          />
          <Box
            position="absolute"
            right="-50%"
            bottom="-15%"
            bgColor="white"
            opacity="15%"
            borderRadius="xl"
            w="90px"
            h="90px"
          />
        </Box>
      </Box>
    </Container>
  );
}
