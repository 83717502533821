import countriesJSON from "./countries.json";
import {extendObj} from "$utils";
import {get as getFlag} from "./flags";
import {orderBy} from 'lodash';


export const countriesByCode = {};

const countriesRef = {
    current : countriesJSON,
};


countriesJSON.map((country,index)=>{
    countriesByCode[country.iso2] = country;
});

export const getAllCountries = ()=> Array.isArray(countriesRef.current) && countriesRef.current.length ? countriesRef.current : countriesJSON;

export const getActiveBuyerCountries = () => {
    let countriesUnfiltered = Array.isArray(countriesRef.current) && countriesRef.current?.length ? countriesRef.current : countriesJSON
    return countriesUnfiltered.filter(el => el.canBuy == true);
}

export const getActiveSellerCountries = () => {
    let countriesUnfiltered = Array.isArray(countriesRef.current) && countriesRef.current.length ? countriesRef.current : countriesJSON
    return countriesUnfiltered.filter(el => el.canSell == true);
}


export const setAllCountries = (countries)=>{
    if(Array.isArray(countries) && countries.length){
        countriesRef.current = countries;
    }
    return countriesRef.current;
}

export const COUNTRIES = {};

export const getAll = (options)=>{
    return orderBy(
        getAllCountries(), // eslint-disable-line global-require
        ['name'],
        ['asc'],
    ).map((country, index) => {
        const code = country.iso2.toUpperCase().trim();
        COUNTRIES[code] = {
            ...country,
            key : index,
            image: getFlag(country.iso2),
            label: country.name,
            iso2: country.iso2,
            code : country.iso2,
            isoCode : country.iso2,
        };
        return COUNTRIES[code];
    });
}

export {getFlag};

export const getCountry = (code) =>{
    if(!code || typeof code !=='string') return null;
    code = code.toUpperCase().trim();
    if((Object.keys(COUNTRIES).length) < 1){
        getAll();
    }
    return COUNTRIES[code] || null;
}