import {fetchCountries} from "$api/location";
import { setAllCountries,getAllCountries,countriesByCode} from "$utils/countries";
import {defaultStr} from "$utils";

/**** fetch list of countries resources
    @param {object} opts, if force, list of countries will be refresh
    @param {boolean} force
*/
export const fetch = (opts,force)=>{
    if(force !== true && hasFetchRef.current){
        return Promise.resolve(getAllCountries());
    }
    if(typeof opts === 'boolean'){
        opts = undefined;
    }
    return fetchCountries(opts).then((countries)=>{
        const allCountries = [];
        countries.map((country,index)=>{
            const code = defaultStr(country.code,country.Code).toLowerCase().trim();
            if(!code || !countriesByCode[code]) return;
            const toUpdate = countriesByCode[code];
            for(let i in country){
                toUpdate[i.ulFirst()] = country[i];
            }
            allCountries.push(toUpdate);
            hasFetchRef.current = true;
            return toUpdate;
        });
        setAllCountries(allCountries);
        return countries;
    });
}

const hasFetchRef = {current:false}