import { Box, Container, Text, Heading, Button, Image, Fade } from "$ui";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import Hero1 from "$images/Hero-1.png";
import Hero2 from "$images/Hero-2.png";
import Hero3 from "$images/Hero-3.png";
import {useI18n} from "$i18n";

export default function Hero() {
  const [slideshow, setSlideshow] = useState(0);
  const [isFade, setIsFade] = useState(true);
  const {t} = useI18n();

  useEffect(() => {
    const animateSlideShow = setInterval(() => {
      let count = slideshow + 1;
      if (count > 2) setSlideshow(0);
      else setSlideshow(count);
    }, 5000);

    return () => clearInterval(animateSlideShow);
  }, [slideshow]);

  return (
    <Box bgColor="primaryLight">
      <Container maxW="6xl">
        <Box
          display="flex"
          flexDirection={{ base: "column-reverse", md: "row" }}
          justifyContent="space-between"
          alignItems={{ base: "left", md: "center" }}
          py={{ base: "1rem", md: "3.25rem", lg: "4rem" }}
          gap={{ base: 4 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={{ base: 2, md: 4 }}
            maxW="520px"
            textAlign="left"
          >
            <Heading as="h1" color="secondary" fontSize="lg">
              {t("ConnexionEnt.Home.Hero.subtitle")}
            </Heading>
            <Heading as="h2" color="primaryDarker">
            {t("ConnexionEnt.Home.Hero.heading")}
            </Heading>
            <Text color="bodyText">
            {t("ConnexionEnt.Home.Hero.body")}
            </Text>
            <Button
              variant="primaryButton"
              >
              {t("ConnexionEnt.Home.Hero.button")}&nbsp;
              <ArrowForwardIcon position="relative" bottom="-.05rem" />
            </Button>
          </Box>
          <Box
            w={{ base: "300px", md: "500px" }}
            h={{ base: "255px", md: "444px" }}
            position="relative"
          >
            <Fade in={slideshow === 0}>
              <Image
                position="absolute"
                w={{ base: "300px", md: "500px" }}
                h={{ base: "255px", md: "444px" }}
                src={Hero1}
              />
            </Fade>
            <Fade in={slideshow === 1}>
              <Image
                position="absolute"
                w={{ base: "300px", md: "500px" }}
                h={{ base: "255px", md: "444px" }}
                src={Hero2}
              />
            </Fade>
            <Fade in={slideshow === 2}>
              <Image
                position="absolute"
                w={{ base: "300px", md: "500px" }}
                h={{ base: "255px", md: "444px" }}
                src={Hero3}
              />
            </Fade>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
