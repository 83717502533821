import React from "react";
import { Box, Heading, Text, Image } from "$ui";
import { PrimaryButton } from "$components/Buttons";
import { useI18n } from "$i18n";

export default function ServiceSnippet({
  headingText,
  bodyText,
  src,
  ...props
}) {
  const { t } = useI18n();
  return (
    <Box
      as="section"
      p="1.5rem"
      display="flex"
      flexDirection="column"
      gap={{ base: 3, md: 5 }}
      borderRadius="md"
      bgColor="surface"
      textAlign="left"
      justifyContent="space-between"
      borderTop="4px solid"
      borderColor="secondary"
      w="100%"
      maxW="700px"
      mx="auto"
      {...props}
    >
      <Heading as="h6">{headingText}</Heading>
      <Text>{bodyText}</Text>
      <Box display="flex" flexDirection="column" gap={{ base: 3, md: 5 }}>
        <Image w="100%" maxH="300px" maxW="700px" src={src} />
        <PrimaryButton iconPosition="right">
          {t("ConnexionEnt.Global.LearnMore")}
        </PrimaryButton>
      </Box>
    </Box>
  );
}
