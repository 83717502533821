import React from "react";
import {
  Container,
  Box,
  HStack,
  StackItem,
  Link as ChakraLink
} from "$ui";
import { Search2Icon } from "@chakra-ui/icons";
import { useI18n } from "$i18n";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { PhoneIcon } from "@chakra-ui/icons";
import LanguagePicker from "./LanguagePicker";
import { CountrySelect } from "$components/Buttons";

export default function TopBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, langCode, links, langs, setLang } = useI18n();
  return (
    <Box display={{base: "none", md: "block"}} bg="primary" textColor="white" textAlign="left">
      <Container maxW="6xl">
        <Box
          py={.35}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          fontSize="sm"
        >
            <ChakraLink
              color="white"
              display="flex"
              alignItems="center"
              gap={3}
            >
              <PhoneIcon color="white" />
              +1 888 567 9090
            </ChakraLink>
          <Box>
            <HStack>
              <StackItem>
                <Search2Icon position="relative" top="-.05rem" />
              </StackItem>
              <StackItem>|</StackItem>
              <StackItem>
                <Link
                  to={`/${langCode}/${t("ConnexionEnt.Links.Contact.link")}`}
                >
                  {t("ConnexionEnt.Global.Contact")}
                </Link>
              </StackItem>
              <StackItem>|</StackItem>
              <StackItem>
                <CountrySelect/>
              </StackItem>
              <StackItem>|</StackItem>
              <StackItem>
                <LanguagePicker/>
              </StackItem>
            </HStack>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
