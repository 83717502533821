import { useSelector, useDispatch } from "react-redux";
import { 
  // setUsername as setStoreUsername, 
  // setLoginType as setStoreLoginType, 
  // setCurrentUserData as setStoreCurrentUserData, 
  setCurrentCountry as setStoreCurrentCountry,
} from "$store/userSlice";

export function useAuth() {
  const dispatch = useDispatch();
  const { 
    // username, 
    // loginType, 
    // currentUserData, 
    currentCountry, 
    ...rest } = useSelector((state) => state.user);

  // const setUsername = (username, ...rest) =>
  //   dispatch(setStoreUsername(username, ...rest));

  // const setLoginType = (LoginType, ...rest) =>
  //   dispatch(setStoreLoginType(LoginType, ...rest));
  
  // const setCurrentUserData = (userObj, ...rest) =>
  //   dispatch(setStoreCurrentUserData(userObj, ...rest));

  const setCurrentCountry = (country, ...rest) =>
    dispatch(setStoreCurrentCountry(country, ...rest));

  
  return {
    // username,
    // setUsername,
    // loginType,
    // setLoginType,
    // currentUserData,
    // setCurrentUserData,
    currentCountry,
    setCurrentCountry,
  };
}
