import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "$auth";
import { Box, List, ListItem, Image } from "$ui";
import { hasFlag } from "country-flag-icons";
import {CheckIcon, ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons"
import { 
  getAllCountries, 
} from "$utils/countries"

export function CountrySelect({showPhone}) {
  const { currentCountry, setCurrentCountry } = useAuth();
  const [opened, setOpened] = useState(false);
  const [countries, setCountries] = useState(
    null
  )
  const listRef = useRef(null);

  useEffect(() => {
    setCountries(getAllCountries())
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [])

  useEffect(() => {
    if (opened) {
      document.getElementById("country-option-" + currentCountry).scrollIntoViewIfNeeded()
    }
  }, [opened])

  const handleOutsideClick = (e) => {
    if (listRef.current && !listRef.current.contains(e.target)) {
      setOpened(false);
    }
  };

  return (
    <Box position="relative">
      {hasFlag(currentCountry) ? (
        <>
        <Box display="flex" width="fit-content" alignItems="center" gap={1} _hover={{cursor: "pointer"}}  onClick={() => setOpened(!opened)}>
          <Image
            alt="United States"
            height="24px"
            width="24px"
            ml=".425rem"
            borderRadius="lg"
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${currentCountry}.svg`}
            />
          {
            opened ? 
            <ChevronUpIcon />
            : 
            <ChevronDownIcon/>
          }
        </Box>
          <Box display={opened ? "block" : "none"} position="absolute" top="0" left="-.325rem" height="30px" w="60px" zIndex={1}>
          </Box>
          </>
      ) : (
        ""
      )}
        <List
        ref={listRef}
            display={opened ? "block" : "none"}
            position="absolute"
            bottom="-256px"
            right="0"
          height="250px"
          width="300px"
          borderRadius="md"
          shadow="xl"
          backgroundColor="white"
          overflowY="scroll"
          zIndex={2}
        >
          {countries && countries.map((country) => {
            return (
              <ListItem className="country" id={"country-option-" + country.code} key={"country-option-" + country.code}
                display="flex" justifyContent="space-between" gap={3} paddingY={3} paddingX={3} w="100%" color="black"
                bgColor={currentCountry === country.code ? "surface" : "none"}
                _hover={{
                    backgroundColor: "#f1f1f1",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    setCurrentCountry(country.code)
                    setOpened(false)
                  }}
              >
                <Box display="flex" alignItems="center" gap={3}>
                  <Image
                    alt={country.name + " flag"}
                    height="25px"
                    width="25px"
                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country.code}.svg`}
                  />
                  <Box>
                      {country.name}
                  </Box>
                </Box>
                <Box display={showPhone ? "block" : "none"} color="#6b6b6b" className="dial-code">+{country.dialCode}</Box>
                <Box display={currentCountry === country.code ? "block" : "none"}>
                  <CheckIcon color="green.300"/>
                </Box>
              </ListItem>
            );
          })}
        </List>
    </Box>
  );
}
