// import { i18nFrLang } from "./lang/i18n.fr";
// import { i18nEnLang } from "./lang/i18n.en";
import extendObj  from "$utils/extendObj";
import {isNonNullString} from "$utils";
import {getLang,setLang} from "./session";

var i = i || 0,j = j || 0; //fix somme for loops bug

export function I18n() {
    this.reset();   
}

I18n.prototype.reset = function(langCode,dictionary){
    const lCode = isNonNullString(langCode) && langCode.trim().toLowerCase();
    this._entities = {}
    this._default = getLang(),
    this._language = lCode  || this._default;
    if(lCode){
        this.setLang(lCode);
    }
    if(typeof dictionary ==='object' && dictionary){
        this.dictionary(dictionary);
    }
}

/*
    * Adds text to the translator's dictionary.
    * If lang_code is null, then dic must be an object consisting of the language and the dictionary elements corresponding to this language
    * Example : dic = {en : {label1: "text1"}
    * If lang_code exists, then dic must be just the dictionary elements. Example : dictionary({label1:'text1'}, "en")
    * Example dictionary('en': {label1:'Text1',label2:'text2'},'fr': {label1:'TextFr1',label2:'textFr2'})
    * dictionary({label1:'textFr1',label2:'textFr2'},'en')
    * @param {type} dict
    * @param {type} lang_code
    * @returns {undefined}
    */
I18n.prototype.dictionary = function(dict,lang_code) {
    if(typeof lang_code == "undefined") {
        for (var i in dict){
            this._dictionary(i,dict[i])
        }
    } else if(typeof dict == "string" && lang_code && typeof lang_code == "object") {
        this._dictionary(dict,lang_code)
    } else if(typeof dict == "object" && typeof lang_code == "string") {
        this._dictionary(lang_code,dict)
    } 
}

I18n.prototype._dictionary = function (lang_code,langs){
    if(typeof langs == "string" & typeof lang_code == "string") {
        return false;
    } else if(typeof lang_code == "object" & typeof langs == "string"){
        var _l = langs
        langs = lang_code
        lang_code = _l
    } else if(typeof lang_code =="object" & typeof langs == "object"){
        return false;
    }

    var _l2 = {}
    _l2[lang_code] = langs
    extendObj(true,this._entities,_l2)
}

I18n.prototype.defaultLanguage = function(lang) {
    this._default = lang || getLang();
    setLang(this._default);
}

/**** 
 * allows to translate the element whose key is passed in parameter
 * @param : key {string|object} the key to translate
 * @param : data {object} the data dictionary to use
 * @param : langCode {string}: the language code to use when it is not defined, the default language is used
 */
I18n.prototype.lang = function(key, data,lang_code) {
    this._language = this._language || this._default || getLang();
    let _r = isNonNullString(key)? key : '';
    if(typeof key == "object" && key){
        for (var i in key){
            _r+=this.localise(key[i],data,lang_code)
        }
    } else if(isNonNullString(key)){
        _r = this.localise(key,data,lang_code)
    }
    return _r;
}
const flatten = (data)=>{
    return data && typeof data =="object" && !Array.isArray(data)? data : {};
}
I18n.prototype.localise = function(key, data,lang_code) {
    key = isNonNullString(key) ? key.trim() :"";
    if(!key) {
        console.error("i18n.language.invalidKey",key,data,lang_code)
        return "i18n.language.invalidKey";
    }
    let language = isNonNullString(lang_code)? lang_code : this._language;
    if(!this._entities[language]){
        language = this._language;
    }
    const locale = flatten(this._entities[language]);
    const translated = locale[key];
    if(translated) return translated;
    /*** la langue par défaut */
    if(isNonNullString(data)){
        return data;
    }
    if (data && typeof data =='object') {
        var _data = flatten(data);
        if(_data[key]){
            return _data[key];
        }
    }
    for(let i in this._entities){
        if(i == language) continue;
        const d = flatten(this._entities[i]);
        const s = d[key];
        if(s) return s;
    }
    return key;
}
I18n.prototype.getDictionary = I18n.prototype.getEntities = function(){
    return this._entities;
}
/*** 
 * set active language, one of lange between supported languages
 * all language files are in miniscule
 * @param string oneOfType : ['en,fr'] //the two languages taken into account for the moment are English and French
 * @param func cb : the callback function in case the language has been loaded, takes as parameter the loaded language
 */
I18n.prototype.setLanguage = I18n.prototype.setLang = function(lang,cb) {
    if(!isNonNullString(lang)) lang = this._default || getLang();
    this._language = lang.toLowerCase();
    setLang(this._language);
    return this;
}

I18n.prototype.setLang = function(lang,cb) {
    return this.setLanguage(lang,cb)
}

I18n.prototype.getLanguage = function() {
    return this._language || this._default;
}

I18n.prototype.getLang = function() {
    return this.getLanguage()
}

/***
 * used to retrieve lang value in dl language file
 * @param {type} label
 * @returns {MS_lang.label}
 */
export function lang(label,def) {
    return i18n.lang(label,def);
}
export const i18n = new I18n();
// 
// i18n.dictionary(i18nEnLang);
// i18n.dictionary(i18nFrLang);

export default i18n;