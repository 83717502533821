import React from "react";
import { Box, Button, Heading, Image, Text, Container, Input } from "$ui";
import BannerSm from "$components/Banners/BannerSm";
import { useI18n } from "$i18n";
import { PrimaryButton } from "$components/Buttons";
import { Textarea } from "@chakra-ui/react";
import Banner from "$images/contact-banner.png";
import FormImg from "$images/contact-form-img.png";

export default function Contact() {
  const { t } = useI18n();

  const contacts = [
    {
      heading: t("ConnexionEnt.Global.Location"),
      body: "Montreal, Quebec",
    },
    {
      heading: t("ConnexionEnt.Global.Email"),
      body: "enterpriseconnexion@gmail.com",
    },
    {
      heading: t("ConnexionEnt.Global.Phone"),
      body: "+1 888 567 9090",
    },
  ];

  return (
    <Box>
      <BannerSm
        src={Banner}
        headingText={t("ConnexionEnt.Contact.Banner.title")}
      />
      <Container maxW="6xl">
        <Box
          my={{ base: "3rem", md: "4rem", lg: "5rem" }}
          mx="auto"
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="center"
          gap={8}
        >
          {contacts.map(({ heading, body }) => {
            return (
              <Box
                p={{base: "2rem", md: "3rem"}}
                display="flex"
                flexDirection="column"
                gap={3}
                bgColor="primaryLight"
                boxShadow="md"
                borderRadius="md"
              >
                <Heading as="h4" fontSize={{base: "md", lg: "2xl"}}>{heading}</Heading>
                <Text>{body}</Text>
              </Box>
            );
          })}
        </Box>
      </Container>
      <Box
        bgColor="surface"
        my={{ base: "3rem", md: "4rem", lg: "5rem" }}
        py={{ base: "3rem", md: "4rem", lg: "5rem" }}
        mx="auto"
      >
        <Container
          maxW="6xl"
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="center"
          gap={8}
        >
          <Box display="flex">
            <Image
              src={FormImg}
              objectFit="cover"
              w="100%"
              height={{ base: "100px", md: "initial" }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            textAlign={{ base: "center", md: "left" }}
          >
            <Heading as="h3">{t("ConnexionEnt.Contact.Form.heading")}</Heading>
            <Text>{t("ConnexionEnt.Contact.Form.body")}</Text>
            <Box
              as="form"
              display="flex"
              flexDirection="column"
              gap={{ base: 3, lg: 5 }}
            >
              <Box
                display="flex"
                flexDirection={{ base: "column", md: "row" }}
                gap={{ base: 3, lg: 5 }}
                justifyContent="center"
              >
                <Input
                  type="text"
                  placeholder={t("ConnexionEnt.Global.Name")}
                />
                <Input
                  type="email"
                  placeholder={t("ConnexionEnt.Global.Email")}
                />
              </Box>
              <Textarea
                height={"100px"}
                placeholder={t("ConnexionEnt.Global.WriteAMessage")}
              />
              <PrimaryButton>{t("ConnexionEnt.Global.Send")}</PrimaryButton>
            </Box>
          </Box>
        </Container>
      </Box>
          <Box as="iframe"
                  mt={{ base: "3rem", md: "4rem", lg: "5rem" }}
            width="100%"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
            allowfullscreen
            src="https://www.google.com/maps/embed/v1/view?zoom=14&center=45.5035%2C-73.5685&key=AIzaSyAaavg-vM0hL2cAW69wPun6_0RRDua8NUw"
          ></Box>
    </Box>
  );
}
