// Copyright 2022 @fto-consult/Boris Fouomene. All rights reserved.
// Use of this source code is governed by a BSD-style
// license that can be found in the LICENSE file.

import isNonNullString from "./validation/isNonNullString";
/**** get first default string value from given arguments
 *  return an empty string if non null string was not set
 *  example : defaultStr(object,object2,null,"string1")=> string1
 *            defaultStr(object,null,null)=>"", 
 */
export default function defaultStr(){
    const args = Array.prototype.slice.call(arguments,0);
    if(args.length == 1){
        let s = args[0];
        if(typeof(s) =="number" && !isNaN(s)){
            return s+"";
        }
        if(isNonNullString(s)) return s;
        return "";
    }
   for(var i in args){
       let v = args[i];
       if(typeof v == "string" && isNonNullString(v)) return v
   }
   return "";
}