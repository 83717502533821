// Copyright 2022 @fto-consult/Boris Fouomene. All rights reserved.
// Use of this source code is governed by a BSD-style
// license that can be found in the LICENSE file.
const navLang = navigator && (navigator.language || navigator.userLanguage) || '';
const lang = typeof navLang =="string" && navLang.toLowerCase().trim() || '';

export const defaultLangs = {
    en : "English",
    fr : "Français",
}

export default lang && lang in defaultLangs && lang || lang.indexOf("fr-") !== -1 && "fr" || "en";