import {
    extendTheme,
    defineStyle,
    defineStyleConfig,
  } from "@chakra-ui/react";

const primaryButton = defineStyle({
    bgColor: "primary",
    color: "white",
    width: "fit-content",
    px: "2rem",
    alignContent: "baseline",
    _active: {
      bgColor: "surfaceText",
      color: "surfaceDark",
    },
    _hover: {
      bgColor: "primaryDark",
    },
  });
  
  const primaryLink = defineStyle({
    color: "primary",
    fontWeight: "semibold",
    width: "fit-content",
    textDecoration: "none"
  });
  
  export const buttonTheme = defineStyleConfig({
    variants: { primaryButton },
  });
  
  export const linkTheme = defineStyleConfig({
    variants: { primaryLink },
  });
  
  export const theme = extendTheme({
    components: {
      Button: buttonTheme,
      Link: linkTheme,
    },
    fonts: {
      heading: `'Poppins', sans-serif`,
      body: `'Poppins', sans-serif`,
    },
    colors: {
      primary: "#00A86B",
      primaryLight: "#F5FFF9",
      primaryDark: "#006741",
      primaryDarker: "#023A26",
      bodyText: "#333333",
      secondary: "#ED8002",
      secondaryDark: '#D37300',
      surface: "#F8F8F8",
      surfaceDark: "#E9E9E9",
      surfaceText: "#9B9B9B",
    },
  });