import React from "react";
import { Box, Container, Button, Heading, Text, List, ListItem } from "$ui";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Divider, Image, ListIcon } from "@chakra-ui/react";
import GreenSquares from "$images/GreenSquares.svg";
import { useI18n } from "$i18n";
import { PrimaryButton } from "$components/Buttons";

export default function SectionTwo() {
  const { t } = useI18n();
  return (
    <Box bgColor="surface">
      <Container
        maxW="6xl"
        py="3rem"
        display="flex"
        gap={5}
        alignItems={{ lg: "center" }}
        justifyContent={{ lg: "space-between" }}
        flexDirection={{ base: "column", lg: "row" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={{ base: 4, md: 6, lg: 8 }}
          textAlign="left"
          maxW="550px"
        >
          <Heading>{t("ConnexionEnt.Home.SectionTwo.heading")}</Heading>
          <Text>{t("ConnexionEnt.Home.SectionTwo.body")}</Text>
          <PrimaryButton
            display={{ base: "none", lg: "flex" }}
            iconPosition="right"
          >
            {t("ConnexionEnt.Home.SectionTwo.button")}
          </PrimaryButton>
        </Box>
        <Box
          h="fit-content"
          flex-grow="1"
          maxW={{ lg: "800px" }}
          w="100%"
          overflow="hidden"
          position="relative"
          bg="white"
          boxShadow="xl"
          borderRadius="md"
          p="2rem"
        >
          <List display="flex" flexDirection="column" gap={{ base: 2, md: 3 }}>
            {[1, 2, 3, 4].map((number) => {
              return (
                <>
                  <ListItem display="flex" alignItems="center">
                    <ListIcon transform="rotate(-45deg)">
                      <ArrowForwardIcon color="primary" />
                    </ListIcon>
                    {t("ConnexionEnt.Home.SectionTwo.List.item" + number)}
                  </ListItem>
                  {number === 4 ? (
                    ""
                  ) : (
                    <Divider orientation="horizontal"></Divider>
                  )}
                </>
              );
            })}
          </List>
          <Image
            style={{ "-webkit-backface-visibility": "hidden" }}
            position="absolute"
            right="-3.5rem"
            bottom="-5rem"
            transform="rotate(-20deg)"
            src={GreenSquares}
          />
        </Box>
        <PrimaryButton
          display={{ base: "flex", lg: "none" }}
          iconPosition="right"
        >
          {t("ConnexionEnt.Home.SectionTwo.button")}
        </PrimaryButton>
      </Container>
    </Box>
  );
}
