import React from "react";
import ReactDOM from "react-dom/client";
import AppEntry from "./AppEntry";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import store from "$store";
import { Provider as ReduxProvider } from "react-redux";
import "./api/axios";
import {theme} from "$theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ChakraProvider theme={theme}>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <AppEntry />
      </BrowserRouter>
    </ReduxProvider>
  </ChakraProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
