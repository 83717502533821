import axios from "axios";
import {isObj} from "$utils"

/*** fetch list of countries */
export const fetchCountries = (opts)=>{
    return axios.get("Basic/GetCountries",opts).then(({data})=>data);
  }
  
  /**** fetch location by Ip */
  export const fetchLocationByIP = ()=>{
    return axios.get("Basic/GetLocationByIP").then(({data})=>{
      return isObj(data.location)? data.location : data;
    });
  }