import { Box, Container, Heading, Text, Button } from "$ui";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import React from "react";
import {useI18n} from "$i18n";

export default function SectionOne() {
  const {t} = useI18n();
  return (
    <Box>
      <Container
        maxW="6xl"
        position="relative"
        display="flex"
        alignItems="center"
        flexDirection="column"
        style={{placeContent: "center"}}
        my={{base: "5.5rem", md: "9rem"}}
      >
        <Box
          position="absolute"
          zIndex="-1"
          style={{ clipPath: "circle(50%)" }}
          bg="linear-gradient(180deg, rgba(251,236,219,1) 0%, rgba(255,255,255,1) 60%)"
          w={{base: "325px", md:"400px"}}
          h={{base: "325px", md:"400px"}}
        />
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          gap={{base:4, md: 6, lg: 8}}
        >
          <Heading as="h2">{t("ConnexionEnt.Home.SectionOne.heading")}</Heading>
          <Text maxW="800px">
          {t("ConnexionEnt.Home.SectionOne.body")}
          </Text>
          <Button
            variant="primaryButton"
            mx="auto"
            >
            {t("ConnexionEnt.Home.SectionOne.button")}&nbsp;
            <ArrowForwardIcon position="relative" bottom="-.05rem" />
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
