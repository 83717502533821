import React from 'react'
import { Box, Container, Heading } from '$ui'

export default function BannerSm({src, headingText, ...props}) {
  return (
    <Box backgroundImage={src} bgColor={!src ? "black" : undefined}>
        <Container h={{base: "180px", md: "240px", lg: "293px"}} maxW="6xl" display="flex" alignItems="center">
            <Heading as="h1" color="white">
                {headingText}
            </Heading>
        </Container>
    </Box>
  )
}
