import { useSelector,useDispatch } from "react-redux";
import i18n from "./i18n";
import { setLangCode, setLangs as iSetLangs, setLinks} from "./slice";
import { useCallback,useMemo } from "react";
import {isNonNullString,defaultStr,isPlainObject,classNames} from "$utils";
import {loadResources,linksResources} from "./resources";
import {Box} from "$ui";
// import { translateLinks } from "$translatedLinks";

export default function useI18n (){
    const dispatch = useDispatch();
    const {langCode,...rest} = useSelector(state=>state.i18n);
    const t = useCallback((key,props)=>{
        const text = i18n.lang(key,null,langCode);
        const isHtml = /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(text);
        if(isHtml){
            const prs = isPlainObject(props)? props : {};
            return <Box as="span" {...prs} dangerouslySetInnerHTML={{__html: text}} className={classNames("i18n-t-html",prs.className)}/>
        }
        if (props) {
            let replacedObjsText = text;
            let textArr = text.split(" ")
            props.forEach((wordToReplace, idx) => {
                let indexToReplace = textArr.findIndex((word) =>
                    word === `{${idx}}`
                );
                textArr[indexToReplace] = wordToReplace
            })
            replacedObjsText = textArr.join(" ")
            return replacedObjsText
        }
        return text;
    },[langCode]);
    // const translatedLinks = useMemo(()=>{
    //     return translateLinks((link)=>{
    //         return  defaultStr(isPlainObject(linksResources[link]) && linksResources[link][langCode], i18n.lang(link));
    //     });
    // },[langCode]);
    const setLang = (code,...rest)=>dispatch(setLangCode(code,...rest));
    const setLinksResources = (links, ...rest) => dispatch(setLinks(links,...rest))
    const isLangSuppported = (langCode)=>{
        if(!isNonNullString(langCode) || !Array.isArray(rest.langs)) return false;
        return !!(rest.langs.filter(l=>defaultStr(l?.Code,l?.code).toLowerCase().trim() === langCode?.toLowerCase().trim())[0]);
    }
    const setLangs = (...args)=> dispatch(iSetLangs(...args));
    return {
        ...rest,
        setLang,
        setLinksResources,
        setLangs,
        langCode,
        linksResources,
        setLangCode : setLang,
        lang : langCode,
        t,
        // translatedLinks,
        // ...translatedLinks,
        translate : t,
        isLangSuppported,
        loadResources : (langCode)=>{
            console.log(langCode)
            if(!isLangSuppported(langCode)){
                return Promise.reject({message:"The langage code is not supported by the application"});
            }
            return loadResources(langCode).then((r)=>{
                setLang(langCode);
                return r;
            });
        }
    }
}