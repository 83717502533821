import { useRadio, useRadioGroup, Box, VStack } from "@chakra-ui/react";
import { useState } from "react";

// 1. Create a component that consumes the `useRadio` hook
function RadioInput(props) {
  const { getInputProps, getRadioProps } = useRadio(props);
    const [isHovered, setHovered] = useState(false)
  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" w="fit-content" display="flex" alignItems={"center"} gap={4} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <input {...input} />
      <Box p="7px" borderRadius="full" bgColor={isHovered ? "surface" : "none"}>
        <Box
          {...checkbox}
          border
          borderWidth="2px"
          p="3px"
          borderRadius="full"
          borderColor="surfaceDark"
          // bgColor="white"
          h="fit-content"
          w="fit-content"
          bgColor="white"
          _checked={{
            bgColor: "primary",
            borderColor:"primary"
          }}
          //   _focus={{
          //     boxShadow: 'outline',
          //   }}
        >
          <Box
            bgColor="white"
            borderRadius={"full"}
            p="4px"
            w="fit-content"
            h="fit-content"
          />
        </Box>
      </Box>
      <Box>{props.children}</Box>
    </Box>
  );
}

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
export default function CustomRadio({ options, ...props }) {
  // const options = ['react', 'vue', 'svelte']

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "react",
    onChange: console.log,
  });

  const group = getRootProps();

  return (
    <VStack {...group} align={"left"}>
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioInput key={value} {...radio}>
            {value}
          </RadioInput>
        );
      })}
    </VStack>
  );
}
