import axios from "axios";
import { extendObj } from "$utils";

export const fetchResourcesPath = "Basic/Resource";

export const lastResourcesVersionPath = "Basic/GetLastVersion";

export const fetchResources = (opts) => {
  return axios.post(
    fetchResourcesPath,
    {
      Categories: [
        "ConnexionEnt.Home",
        "ConnexionEnt.Services",
        "ConnexionEnt.Commitments",
        "ConnexionEnt.Projects",
        "ConnexionEnt.Contact",
        "ConnexionEnt.Global",
        "ConnexionEnt.Nav",
        "ConnexionEnt.Footer",
        "ConnexionEnt.Links"
      ],
    },
    extendObj(true, {}, opts, {
      headers: { "Content-Type": "application/json" },
    })
  );
};

export const fetchLinksResources = (langCode, opts) => {
  let headers = { "Content-Type": "application/json" }

  if (langCode) {
    headers.Languagecode = langCode
  }

  return axios.post(
    fetchResourcesPath,
    { Categories: ["ConnexionEnt.Links"] },
    extendObj(true, {}, opts, {
      headers,
    })
  );
};

export const fetchLanguages = (opts) => {
  return axios.get(
    "Basic/GetLanguages",
    extendObj(true, {}, opts, {
      headers: { "Content-Type": "application/json" },
    })
  );
};

export const fetchLastVersion = (opts) => {
  return axios.get(lastResourcesVersionPath, opts);
};
