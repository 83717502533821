export const STATE_CHANGE = 'STATE_CHANGE';

export const STATE_ACTIVE = "STATE_ACTIVE";

export const STATE_INACTIVE = "STATE_INACTIVE";

export const GO_ONLINE = "GO_ONLINE";

export const GO_OFFLINE = "GO_OFFLINE";

export const AUTH_LOGIN_USER = "AUTH_LOGIN_USER";

export const AUTH_LOGOUT_USER= "AUTH_LOGOUT_USER";

export const AUTH_UPSERT_LOGGED_USER = "AUTH_UPSERT_LOGGED_USER";

export const UPDATE_THEME = "UPDATE_THEME";

export const RESIZE_PAGE  = "RESIZE_PAGE";
export const UPDATE_LANG = "UPDATE_LANG";

export const TOGGLE_MOBILE_NAV = "TOGGLE_MOBILE_NAV";
export const LOAD_I18N_RESOURCES  = "LOAD_I18N_RESOURCES";
export const TOGGLE_SIDEBAR_NAV = "TOGGLE_SIDEBAR_NAV";

