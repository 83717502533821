import React from "react";
import { Box, Button, Heading, Image, Text, Container } from "$ui";
import BannerSm from "$components/Banners/BannerSm";
import { CommitmentSnippet } from "$components/Sections/CommitmentSnippet";
import { useI18n } from "$i18n";

export default function Commitments() {
  const { t } = useI18n();
  return (
    <Box mb={{ base: "3rem", md: "4rem", lg: "5rem" }}>
      <BannerSm headingText={t("ConnexionEnt.Commitments.Banner.title")} />
      <Container maxW="6xl">
        <Box
          my={{ base: "3rem", md: "4rem", lg: "5rem" }}
          mx="auto"
          maxW="3xl"
        >
          <Text>{t("ConnexionEnt.Commitments.desc")}</Text>
        </Box>
      </Container>
      <Box display="flex" flexDirection="column">
        <CommitmentSnippet
          headingText={t("ConnexionEnt.Commitments.Section1.heading")}
          bodyText={t("ConnexionEnt.Commitments.Section1.body")}
          reverse
        />
        <CommitmentSnippet
          headingText={t("ConnexionEnt.Commitments.Section2.heading")}
          bodyText={t("ConnexionEnt.Commitments.Section2.body")}
        />
        <CommitmentSnippet
          headingText={t("ConnexionEnt.Commitments.Section3.heading")}
          bodyText={t("ConnexionEnt.Commitments.Section3.body")}
          reverse
        />
        <CommitmentSnippet
          headingText={t("ConnexionEnt.Commitments.Section4.heading")}
          bodyText={t("ConnexionEnt.Commitments.Section4.body")}
        />
      </Box>
    </Box>
  );
}
