import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
      // username: null,
      // loginType: "Email",
      // currentUserData: null,
      currentCountry: null
    },
    reducers: {
      // setInitUsername : (state, action) => {
      //   state.initialLogin.username = action.payload
      // },
      // setInitLoginType : (state, action) => {
      //   state.initialLogin.loginType = action.payload
      // },
      // setUsername : (state, action) => {
      //   state.username = action.payload
      // },
      // setLoginType : (state, action) => {
      //   state.loginType = action.payload
      // },
      // setCurrentUserData : (state, action) => {
      //   state.currentUserData = action.payload
      // },
      setCurrentCountry : (state, action) => {
        state.currentCountry = action.payload
      },
    },
});

export const {
  // setUsername, 
  // setLoginType, 
  // setCurrentUserData, 
  setCurrentCountry, 
  // setInitUsername, 
  // setInitLoginType
} = userSlice.actions;

export default userSlice.reducer;