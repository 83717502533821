
const hasIntl = typeof window.Intl !="undefined" && window.Intl && typeof Intl.DateTimeFormat =="function" && true || false;
const { countries, zones } = require("moment-timezone/data/meta/latest.json");

const timeZoneCityToCountry = {};

Object.keys(zones).forEach(z => {
  const cityArr = z.split("/");
  const city = cityArr[cityArr.length-1];
  timeZoneCityToCountry[city] = countries[zones[z].countries[0]];
});
export const getBrowserCordinates = ()=>{
    if(!hasIntl) return null;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzArr = userTimeZone.split("/");
    const city = tzArr[tzArr.length - 1] || '';
    const country = timeZoneCityToCountry[city] || null;
    return {
        region : tzArr[0] || '',
        city,
        country,
        countryCode : country && country.abbr || "",
        countryName : country && country.name || "",
        countryZones : country && country.zones || [],
    }
}

export {timeZoneCityToCountry};